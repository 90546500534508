import React from 'react';
import {graphql, Link} from 'gatsby';
import PropTypes from 'prop-types';
import Slider from "react-slick";

import Layout from '../components/layout';
import PageHero from '../components/pageHero/PageHero';
import Section from '../components/section/Section';
import House from "../components/house/House";
import PostContent from "../components/postContent/postContent";
import Single from "../components/news/Single";

const postTemplate = ({data: {postData, posts: {edges: posts}, frontPageHouses: {edges: frontPageHouses}}}) => {
    const postsItems = posts.slice(0, 3);
    const settings = {
        centerMode: true,
        centerPadding: 0,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,
        focusOnSelect: true,
        waitForAnimate: false,
        cssEase: 'ease-out',
        arrows: true
    };

    return (
        <Layout post>
            <PageHero post header={postData.title} className="pb-0 pb-lg-13">
                <div className="d-flex d-lg-block justify-content-center mb-lg-1">
                    <p className="mr-4 fs-s16 fs-slg-18 fw-bold d-inline">{postData.date}</p>
                </div>
            </PageHero>
            <Section header="Treść posta" hideHeader className="section-dark pt-lg-5 pb-lg-10">
                <div className="row">
                    <PostContent postData={postData}/>
                    <div className="col-12 col-lg-5">
                            {postsItems.map((post, i) => (
                                <div className="mb-lg-4" key={i}>
                                    <Single single={post}/>
                                </div>
                            ))}
                    </div>
                </div>
            </Section>
            <Section header="Poznaj nasze domy" className="houses">
                <div className="row d-flex justify-content-center align-content-center">
                    <div className="col-12 col-lg-8 text-center d-flex align-items-center flex-column d-none d-lg-block">
                        <div className="d-flex flex-row justify-content-center">
                            {frontPageHouses.map((houseItem, i) => {
                                return (
                                    <Link key={i} className="text-decoration-none d-flex flex-row justify-content-center" to={'/budynki/' + houseItem.node.slug}>
                                        <House className="m-2" key={i} houseData={houseItem} />
                                    </Link>
                                )
                            })}
                        </div>
                    </div>

                    <div className="col col-lg-8 text-center d-flex align-items-center flex-column d-block d-lg-none">
                        <div className="w-75 d-flex flex-column align-content-center justify-content-center mb-6">
                            <Slider {...settings}>
                                {frontPageHouses.map((houseItem, i) => {
                                    return (
                                        <Link key={i} className="text-decoration-none d-flex justify-content-center" to={'/budynki/' + houseItem.node.slug}>
                                            <House className="m-2" key={i} houseData={houseItem} />
                                        </Link>
                                    )
                                })}
                            </Slider>
                        </div>
                    </div>

                    <div className="d-flex flex-column text-center align-items-center mb-6">
                        <p className="fs-s24 w-75 text-center mb-4 mb-lg-10">Mamy w ofercie większą chatę, a w planach kolejne otwarcia nowych mniejszych domków. Aby dowiedzieć się więcej i być na czasie kliknij poniżej</p>
                        <Link to="/znizki-ciekawostki-terminy/"><button className="btn btn-outline-primary fw-normal">Chcę więcej info</button></Link>
                    </div>
                </div>
            </Section>
        </Layout>
    )
}

postTemplate.propTypes = {
    data: PropTypes.object.isRequired,
}

export default postTemplate;

export const pageQuery = graphql`
    query($wordpressId: Int!) {
        postData: wordpressPost(wordpress_id: {eq: $wordpressId}) {
            title
            content
            featured_media {
              localFile {
                childImageSharp {
                    fluid(quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
              }
            }
            date(formatString: "YYYY.MM.DD")
        }
        posts: allWordpressPost {
            edges {
              node {
                id
                wordpress_id
                status
                link
                date(formatString: "YYYY.MM.DD")
                title
                featured_media {
                  localFile {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                  }
                }
              }
            }
        }
        frontPageHouses: allWordpressWpHouses(sort: {fields: wordpress_id, order: ASC})  {
            edges {
                node {
                    acf {
                        people
                        space
                        icon {
                            localFile {
                                publicURL
                            }
                        }
                    }
                    slug
                    title
                    wordpress_id
                }
            }
        }
    }
`;