import React from 'react';
import Img from "gatsby-image";
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import './scss/index.scss'

import { plugins } from '../../../gatsby-config';

function PostContent({postData}) {
    const postContent = postData.content.split(`<!--more-->`)

    const webAddress = plugins[5].options.protocol + '://' + plugins[5].options.baseUrl;
    const contentWithImages = postContent[1].split('wp-content/').join(webAddress + '/wp-content/');

    return (
            <div className="col-12 col-lg-7 p-3 p-lg-0 fs-s21 lh-lg post-content">
                <div className="mb-lg-6 fw-bold">{ ReactHtmlParser(postContent[0]) }</div>
                <Img className="mb-lg-8" fluid={postData.featured_media ? postData.featured_media.localFile.childImageSharp.fluid : ''} alt={postData.title}/>
                <div>{ ReactHtmlParser(contentWithImages) }</div>
            </div>
    );
}

PostContent.propTypes = {
    postData: PropTypes.object.isRequired,
}


export default PostContent;