import React from "react"
import { Link } from "gatsby";
import Img from "gatsby-image"

import placeholder from "../../images/placeholder.jpg"

import "./scss/index.scss"

const Single = ({ single }) => {

    let featuredImage;
    if (single.node.featured_media) {
        featuredImage = <Img className="news-image small" fluid={single.node.featured_media.localFile.childImageSharp.fluid} />
    } else {
        featuredImage = <img src={placeholder} className="news-image small" alt="Placeholder" />
    }

    return (
        <article className="news-single news-row">
            {featuredImage}
            <header className="news-header-small">
                <h3>
                    <Link className="text-decoration-none" to={'/aktualnosci/' + single.node.link}>
                        {single.node.title}
                    </Link>
                </h3>
                <div className="news-more">
                    <div className="news-meta ">
                        <span>{single.node.date}</span>
                    </div>
                    <Link className="news-link" to={'/aktualnosci/' + single.node.link}>więcej <svg xmlns="http://www.w3.org/2000/svg" width="20" height="35" viewBox="0 0 35 35"><g id="Group_12" data-name="Group 12" transform="translate(-468.5 -1678)"><circle id="Ellipse_2" data-name="Ellipse 2" cx="17.5" cy="17.5" r="17.5" transform="translate(468.5 1678)" fill="#fff" opacity="0"/><path fill="currentColor" id="Path_708" data-name="Path 708" d="M4.883-6.836A.851.851,0,0,0,5.2-7.5a.851.851,0,0,0-.312-.664L-2.7-15.742a.9.9,0,0,0-.664-.273.9.9,0,0,0-.664.273l-.859.859a.977.977,0,0,0-.293.664.84.84,0,0,0,.254.664L1.133-7.5-4.922-1.445a.84.84,0,0,0-.254.664.977.977,0,0,0,.293.664l.859.859a.9.9,0,0,0,.664.273A.9.9,0,0,0-2.7.742Z" transform="translate(486.5 1703)"/></g></svg></Link>
                </div>
            </header>
        </article>
    )
}
export default Single