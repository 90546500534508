require("dotenv").config({
  path: `.env`,
})

module.exports = {
  siteMetadata: {
    title: `Szczurze wzgórze`,
    description: `Cisza i natura na wyciągnięcie ręki`,
    author: `@ideeconcept`,
  },
  plugins: [
    `gatsby-plugin-react-helmet`,
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`,
      },
    },
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `gatsby-starter-default`,
        short_name: `starter`,
        start_url: `/`,
        background_color: `#af0341`,
        theme_color: `#af0341`,
        display: `minimal-ui`,
        icon: `src/images/sw-favicon.png`, // This path is relative to the root of the site.
      },
    },
    {
      resolve: `gatsby-source-wordpress`,
      options: {
        excludedRoutes: [
          `/batch/v1*`,
          `/wp/v2/users/**`,
          `/wp/v2/settings*`,
          `/wp/v2/themes*`,
          `/wp/v2/templates*`,
          `/wp/v2/templates/lookup*`,
          `/wp/v2/template-parts*`,
          `/wp/v2/template-parts/lookup*`,
          `/wp/v2/menus*`,
          `/wp/v2/menu-locations*`,
          `/wp/v2/menu-items*`,
          `/wp/v2/sidebars*`,
          `/wp/v2/widget-types*`,
          `/wp/v2/widgets*`,
          `/wp/v2/pattern-directory/patterns*`,
          `/wp/v2/block-patterns/**`,
          `/wp/v2/block*`,
          `/wp/v2/block-directory/**`,
          `/wp/v2/plugins`,
          `/wp/v2/menu-items/**`,
          `/mdb-api/**`,
          `/realmedialibrary/**`,
          `/regenerate-thumbnails/**`,
          `/real-utils/**`,
          `/wp-site-health/v1/**`,
          `/wp-block-editor/v1/url-details*`,
          `/wp-block-editor/v1/export*`,
        ],
        baseUrl: `szczurze-wzgorze.4beta.pl`,
        protocol: `https`,
        hostingWPCOM: false,
        useACF: true,
        searchAndReplaceContentUrls: {
          sourceUrl: `https://szczurze-wzgorze.4beta.pl/`,
          replacementUrl: ``,
        },
        minimizeDeprecationNotice: true,
      },
    },
    `gatsby-plugin-sass`,
    `gatsby-plugin-smoothscroll`,
    // this (optional) plugin enables Progressive Web App + Offline functionality
    // To learn more, visit: https://gatsby.dev/offline
    // `gatsby-plugin-offline`,
    {
      resolve: `@ccalamos/gatsby-source-googlemaps-static`,
      options: {
        key: "AIzaSyBAYVxONEd6w-0N1qK3ZdEC4-h8JA1A-pc",
        center: "Chicago, IL",
        query: "Willis Tower",
        size: "1280x555",
        scale: "6",
      },
    },
  ],
}
